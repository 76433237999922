<template>
  <v-container>
    <v-row justify="center">
      <h2>Volkswagen Arteon R</h2>
    </v-row>
    <v-row class="ma-10 d-none d-lg-flex" justify="center" no-gutters>
      <v-timeline
          :dense="$vuetify.breakpoint.smAndDown"
      >
        <div v-for="card in cards" :key="card.id">
          <v-timeline-item
              fill-dot
              color="red"
          >
            <span slot="opposite">{{ card.timeline_title }}</span>
            <v-card class="elevation-4">
              <v-card-title class="headline">
                <a :id="card.id"></a>{{ card.title }}
              </v-card-title>
              <v-card-text>
                <v-img v-for="image in card.images" :key="image.id"
                       :src=image.src
                       :lazy-src=image.lazy_src
                       class="my-4"
                />
                <p v-html="card.content" />
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </div>
      </v-timeline>
    </v-row>

    <v-row class="ma-10 d-sm-flex d-md-flex d-lg-none d-xl-none"  justify="center" no-gutters>
      <timeline>
        <div v-for="card in cards" :key="card.id">
          <timeline-title font-color=""><a :id="card.id"></a><h2>{{ card.timeline_title }}</h2></timeline-title>
          <timeline-item font-color="" :hollow="true">
            <h3>{{card.title}}</h3>
            <v-img v-for="image in card.images" :key="image.id"
                   :src=image.src
                   :lazy-src=image.lazy_src
                   max-width="80vw"
                   class="my-4"
            />
            <p v-html="card.content" />
          </timeline-item>
        </div>
      </timeline>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

import { Timeline, TimelineItem, TimelineTitle } from 'vue-cute-timeline'

export default {
  name: 'Arteon',
  components: {
    Timeline,
    TimelineItem,
    TimelineTitle
  },
  data: () => ({
    cards: [
      {
        id: 7,
        timeline_title: "September 2022",
        title: "Mõned pildid ka lõpuks",
        images: [
          {
            id: 1,
            src: "images/ArteonR/20220926/1_IMG_2689.jpeg",
            lazy_src: "images/ArteonR/20220926/thumb/1_IMG_2689.jpeg"
          },{
            id: 2,
            src: "images/ArteonR/20220926/2_A83B599B-BA45-4E66-95CC-907F432BF423.jpeg",
            lazy_src: "images/ArteonR/20220926/thumb/2_A83B599B-BA45-4E66-95CC-907F432BF423.jpeg"
          },{
            id: 3,
            src: "images/ArteonR/20220926/3_AE6236E1-7A82-4432-9EDB-E5AB76786223.jpeg",
            lazy_src: "images/ArteonR/20220926/thumb/3_AE6236E1-7A82-4432-9EDB-E5AB76786223.jpeg"
          },{
            id: 4,
            src: "images/ArteonR/20220926/4_IMG_2736.jpeg",
            lazy_src: "images/ArteonR/20220926/thumb/4_IMG_2736.jpeg"
          },{
            id: 5,
            src: "images/ArteonR/20220926/5_50F03130-CBC9-455D-A265-3BD7BCC7D373.jpeg",
            lazy_src: "images/ArteonR/20220926/thumb/5_50F03130-CBC9-455D-A265-3BD7BCC7D373.jpeg"
          },{
            id: 6,
            src: "images/ArteonR/20220926/6_B7590260-7414-49B9-8B69-D34A3803D72C.jpeg",
            lazy_src: "images/ArteonR/20220926/thumb/6_B7590260-7414-49B9-8B69-D34A3803D72C.jpeg"
          },{
            id: 7,
            src: "images/ArteonR/20220926/7_IMG_3148.jpeg",
            lazy_src: "images/ArteonR/20220926/thumb/7_IMG_3148.jpeg"
          },{
            id: 8,
            src: "images/ArteonR/20220926/8_IMG_4219.jpeg",
            lazy_src: "images/ArteonR/20220926/thumb/8_IMG_4219.jpeg"
          },
        ],
        content: "Auto on aasta vana aga ühtegi pilti veel pole siin .. no nüüd on 😀"
      },
      {
        id: 6,
        timeline_title: "21. september 2021",
        title: "Et siis mis üldse tellitud sai?",
        content: "myVolkswageni portaal ( <a target='_blank' href='https://www.volkswagen.ee/'>https://www.volkswagen.ee/</a> ) näitab varustuse lehel nii imelist infot, et ma kopeerin ja pasteerin selle kõik otse siia." +
            "<br />" +
            "<br /><b>Mudel:</b> Arteon R 2.0 l TSI GPF 4MOTION" +
            "<br /><b>Võimsus:</b> 235 kW (320 PS)" +
            "<br /><b>Mudeliaasta:</b> 2022" +
            "<br /><b>Värvid:</b> Oryx White Mother-of-Pearl Effect" +
            "<br /><br /><b>Peale sai kõik, mis tellimise hetkel varustuse nimekirjas olemas oli .. seepärast ka nõnna pikk list siin :D</b>" +
            "<br /><br /><ul>" +
            "<li>Wheelbase</li>" +
            "<li>Dual-clutch transmission DSG DQ500</li>" +
            "<li>Standard manufacturing sequence</li>" +
            "<li>Without alternative drive system</li>" +
            "<li>Fuel tank</li>" +
            "<li>Elimination of nameplate for engine designation on luggage compartment lid</li>" +
            "<li>Rear tailpipe</li>" +
            "<li>With electronic steering column lock</li>" +
            "<li>Labels in English</li>" +
            "<li>Floor mats in front and rear</li>" +
            "<li>Information kit in Estonian</li>" +
            "<li>Cold zones</li>" +
            "<li>Weight range 3 installation control only, no requirement forecast</li>" +
            "<li>Fuel system for fuel-injected SI engine</li>" +
            "<li>Electronic Stability Control and electromechanical brake booster</li>" +
            "<li>Activated carbon canister (Evaporative Emission Control System (EVAP))</li>" +
            "<li>Special identification label for EC for M1 passenger vehicles</li>" +
            "<li>Alloy spare wheel with original equipment tire</li>" +
            "<li>Rear shock absorption, sports version</li>" +
            "<li>Disc brakes in rear</li>" +
            "<li>Disc brakes in front</li>" +
            "<li>Folding trailer hitch, with electric release</li>" +
            "<li>Progressive steering</li>" +
            "<li>Wheel locks with extended theft protection</li>" +
            "<li>Mass damper for steering wheel, 36 Hz</li>" +
            "<li>Tool kit and jack</li>" +
            "<li>Without additional front underbody guard</li>" +
            "<li>Warning triangle</li>" +
            "<li>Four-wheel drive 4MOTION</li>" +
            "<li>Leather-wrapped multi-function sports steering wheel, heated, with touch control and shifting paddles</li>" +
            "<li>Electronic differential lock XDS</li>" +
            "<li>Initial standard fuel filling</li>" +
            "<li>Without electric motor (hybrid)</li>" +
            "<li>Without delivery equipment</li>" +
            "<li>Preparation for mobile key for vehicle - access via smartphone</li>" +
            "<li>Adaptive Chassis Control DCC incl. Driving Profile Selection</li>" +
            "<li>Comfort bumpers</li>" +
            "<li>Front seats with power adjustment, ergoComfort seat on driver side, with mem. feature a. convenience entry funct.</li>" +
            "<li>Without additional tires</li>" +
            "<li>License plate carrier in front and rear (ECE)</li>" +
            "<li>ISOFIX anchorage points for mounting of 2 child seats on rear bench seat, also for i-Size child seats</li>" +
            "<li>Without partition</li>" +
            "<li>Decorative inserts \"Piano Black\" for center console</li>" +
            "<li>Tilting and sliding panoramic sunroof</li>" +
            "<li>Luggage compartment floor</li>" +
            "<li>Non-folding front passenger seat backrest</li>" +
            "<li>Front head restraints integrated into the seat</li>" +
            "<li>Unsplit rear bench seat, asymmetric split folding backrest, with center armrest and load-through hatch</li>" +
            "<li>Door and side trim panel</li>" +
            "<li>3-point seat belts in front with height adjustment and seat belt pretensioners</li>" +
            "<li>3 head restraints in rear</li>" +
            "<li>Luggage compartment cover</li>" +
            "<li>Additional interior noise suppression</li>" +
            "<li>Without roll-up sunshade</li>" +
            "<li>3-point seat belts with seat belt pretensioners for the outer rear seats</li>" +
            "<li>Heated front and outer rear seats</li>" +
            "<li>Body-colored lower side trims</li>" +
            "<li>Emission standard EU6 AP</li>" +
            "<li>Without seat ventilation/massage seat</li>" +
            "<li>Keyless locking and starting system Keyless Access with SAFELOCK</li>" +
            "<li>Laminated safety glass windshield, heated (w/o wires), infrared-reflecting windshield, sound-insulating</li>" +
            "<li>Auto-dimming interior rearview mirror</li>" +
            "<li>Driver and front passenger airbag with front passenger airbag deactivation, including knee airbag on driver side</li>" +
            "<li>Without front passenger seat control</li>" +
            "<li>Side airbags for outer rear seats, incl. side airbags in front and curtain airbag system</li>" +
            "<li>Chrome moldings on the side windows</li>" +
            "<li>Without special body measures</li>" +
            "<li>Carrier frequency 433.92 MHz-434.42 Mhz</li>" +
            "<li>Easy Open & Close - sensor controlled luggage compartment opening and closing with remote unlocking</li>" +
            "<li>Transport protection film (minimum protection) with additional transport protection measures</li>" +
            "<li>Right exterior mirror, convex</li>" +
            "<li>Aspherical exterior mirror on driver side</li>" +
            "<li>Illuminated vanity mirrors in sun visors</li>" +
            "<li>Without fire extinguisher</li>" +
            "<li>Front center armrest with storage compartment, 2 rear vents</li>" +
            "<li>Travel Assist, Lane Assist and Emergency Assist</li>" +
            "<li>Autonomous Emergency Braking Front Assist (for Adaptive Cruise Control ACC up to 210 km/h)</li>" +
            "<li>Titanium Black headliner</li>" +
            "<li>Gearshift knob in leather</li>" +
            "<li>Luggage compartment mat</li>" +
            "<li>LED light unit in front footwell</li>" +
            "<li>Ext. mirrors power-folding, auto-dimming on driver side, surround lighting and memory feature</li>" +
            "<li>Electronic restriction of top speed</li>" +
            "<li>Anti-theft alarm system with interior monitoring, backup horn, and towing protection</li>" +
            "<li>12-V outlet in center console in rear</li>" +
            "<li>Without heat accumulator/auxiliary heater</li>" +
            "<li>Without preparation for VTS (vehicle tracking system)</li>" +
            "<li>Tire Pressure Monitoring System</li>" +
            "<li>Start-stop system with regenerative braking</li>" +
            "<li>Scuff plates in door apertures</li>" +
            "<li>Without CD/DVD for navigation</li>" +
            "<li>Decorative inserts \"Black Carbon\" for dashboard and door trim panels</li>" +
            "<li>Standard engine starter</li>" +
            "<li>Navigation system \"Discover Pro\" incl. Streaming & Internet</li>" +
            "<li>Proactive passenger protection system in combination with Front Assist and Side Assist</li>" +
            "<li>Park Assist with Park Distance Control</li>" +
            "<li>Lane change system Side Assist</li>" +
            "<li>Vehicle class differentiation -3H0-</li>" +
            "<li>Radio</li>" +
            "<li>Non-hot country plus auxiliary radiator</li>" +
            "<li>Installation of SLI battery in rear, without second battery</li>" +
            "<li>Advanced high-beam control Dynamic Light Assist</li>" +
            "<li>Alternator 180 A</li>" +
            "<li>Power-adjustable lumbar supports in front, massage feature on driver side</li>" +
            "<li>LED headlamps with LED separate daytime running light and dynamic cornering light</li>" +
            "<li>Aut. headlight control with separate daytime running light, entry lighting and manual exit lighting</li>" +
            "<li>Rain sensor</li>" +
            "<li>Dynamic headlight range control with dynamic cornering light</li>" +
            "<li>Harman Kardon sound system, 10+1 speakers, 700 W total power output, digital 16-chan. amplifier, subwoofer</li>" +
            "<li>Interior lights with switch-off delay and dimmer, 2 LED reading lights in front and 2 in rear</li>" +
            "<li>Adaptive Cruise Control ACC stop & go incl. speed limiter</li>" +
            "<li>Rear fog light on one side, back-up light on both sides</li>" +
            "<li>3D LED rear combination lamps with dynamic turn signal</li>" +
            "<li>Cornering light and poor weather light</li>" +
            "<li>Without headlamp washer system</li>" +
            "<li>Two-tone horn</li>" +
            "<li>Diversity antenna for FM reception</li>" +
            "<li>Without integrated toll system</li>" +
            "<li>Without preparation for two-way radio installation</li>" +
            "<li>Mobile phone interface \"Comfort\" with inductive charging feature</li>" +
            "<li>Non-smoker's package: 12-V outlet in front</li>" +
            "<li>Without auxiliary heater/parking heater</li>" +
            "<li>Warning signal and warning lamp for front and rear seat belts not fastened</li>" +
            "<li>Multi-color Digital Cockpit \"Pro\" selection of different info profiles possible</li>" +
            "<li>Automatically-heated washer nozzles in front</li>" +
            "<li>LED entry/warning lights in front doors</li>" +
            "<li>App-Connect incl. App-Connect Wireless for Apple CarPlay and Android Auto</li>" +
            "<li>230-V outlet in center console in rear</li>" +
            "<li>Category 0</li>" +
            "<li>Top sports</li>" +
            "<li>Driving on the right</li>" +
            "<li>Part set without country-specific prescriptive standard</li>" +
            "<li>Type approval country Estonia</li>" +
            "<li>Operating permit, alteration</li>" +
            "<li>4 alloy wheels \"Estoril\" 8J x 20, Black, diamond-turned</li>" +
            "<li>4-cylinder SI engine 2.0 l TSI, 235 kW</li>" +
            "<li>No special edition</li>" +
            "<li>ECO function</li>" +
            "<li>Online service, with OCU, with head unit coding, without engine immobilizer interconnection</li>" +
            "<li>Driver alert system</li>" +
            "<li>Regional code \" ECE \" for radio</li>" +
            "<li>Without vehicle inlet</li>" +
            "<li>Without charging cable</li>" +
            "<li>No special purpose vehicle, standard equipment</li>" +
            "<li>Standard paint coating</li>" +
            "<li>Customized installation</li>" +
            "<li>Variant</li>" +
            "<li>Shock absorption in front</li>" +
            "<li>7-speed automatic transmission or dual-clutch transmission DSG for four-wheel drive, note: PR family EDF</li>" +
            "<li>Electronic engine sound</li>" +
            "<li>Vehicles with special upgrade measures</li>" +
            "<li>AirStop® tires 245/35 R20</li>" +
            "<li>Standard curb weight range</li>" +
            "<li>Battery 380 A (68 Ah)</li>" +
            "<li>Coupe</li>" +
            "<li>Area View incl. rear view camera system</li>" +
            "<li>Air Care Climatronic 3 zone automatic climate control with enhanced air filter and controls in the rear</li>" +
            "<li>Refrigerant R1234yf</li>" +
            "<li>Head-up display</li>" +
            "<li>Left-hand drive</li>" +
            "<li>Suspension range 51 installation control only, no requirement forecast</li>" +
            "<li>Inserts and inner sides of bolsters of front a. outer rear seats in leather \"Nappa\"/\"Nappa Carbon Style\"</li>" +
            "<li>Standard battery/alternator capacity</li>" +
            "<li>Emergency call service; no registration required, service is activated upon delivery</li>" +
            "<li>LED headlamps incl. advanced high-beam control Dynamic Light Assist</li>" +
            "<li>Side airbags for outer rear seats, incl. side airbags in front and curtain airbag system</li>" +
            "<li>Acoustics package</li>" +
            "<li>Easy Open & Close package with SAFELOCK</li>" +
            "<li>Heated (without wires) and infrared-reflecting windshield</li>" +
            "<li>ergoComfort seat with 14-way adjustment with memory feature on driver side</li>" +
            "<li>\"Estoril\" 8J x 20, Black, diamond-turned, Volkswagen R, AirStop® tires 245/35 R20</li>" +
            "<li>Winter package in combination with leather package</li>" +
            "<li>Sports seats in front</li>" +
            "<li>Without child seat</li>" +
            "<li>LongLife Service Regime</li>" +
            "<li>Voice control</li>" +
            "<li>Service indicator 30 000 km or 2 years (variable)</li>" +
            "<li>Chrome elements on door pulls, on the rotary light switch and on the front vents</li>" +
            "<li>Multifunction camera</li>" +
            "<li>30-color ambient lighting</li>" +
            "<li>Dynamic Road Sign Display</li>" +
            "<li>Digital radio reception DAB+</li>" +
            "<li>Navigation system \"Discover Pro\" incl. Streaming & Internet</li>" +
            "<li>Model update, week 22</li>" +
            "<li>4-cylinder SI engine 2.0 l</li>" +
            "<li>Instrument cluster with electronic speedometer, odometer and trip odometer, tachometer</li>" +
            "<li>USB-C interface and USB charging socket</li>" +
            "<li>Hill Start Assist</li>" +
            "<li>Electromechanical parking brake with Auto Hold function</li>" +
            "<li>Tires without specification of tire brand</li>" +
            "<li>Without garage door opener</li>" +
            "<li>Pedals in brushed stainless steel</li>" +
            "<li>Pedestrian monitoring and re-active hood</li>" +
            "<li>Sound-insul. laminated safety glass for front side windows; privacy glass in rear window and rear side windows</li>" +
            "<li>VE: NCAP package</li>" +
            "<li>Anti-theft alarm system</li>" +
            "<li>Lights & Vision package incl. high-beam control Light Assist</li>" +
            "<li>Leather package \"Nappa\"/\"Nappa Carbon Style\", Volkswagen R</li>" +
            "<li>Preparation for We Connect and We Connect Plus</li>" +
            "</ul>"
      },
      {
        id: 5,
        timeline_title: "21. september 2021",
        title: "Uudised .. lõpuks .. jälle",
        content: "Vahepeal on loomulikult olnud veel mõningaid uudised selle kohta kuidas mitte midagi mitte kuskil ei liigu 😬\n" +
            "<br />Septembri esimestel päevadel sai aga selgeks, et auto on lõpuks valmis ning asub Tartu poole teele.\n" +
            "<br />Võiks arvata, et kaua seda sealt ikka siia tuuakse - Arteone tehakse Emdeni tehases .. mis on kohe sadamas .. ja sadamast käib praam Paldiskisse. Aga ei … <b>Møller Baltic Import</b> oskab neid meile tuua hhhooooopis erilisemal viisil - küll topitakse auto rongile, siis laevale ja lennukile ja hobuvankrile ja jumal teab millele. Siis sõidutatakse kaks tiiru läbi euroopa kuni lõpuks jõuab kolm nädalat hiljem Tartusse. <b>VÕIBOLLA</b> .. jõuab Tartusse 😃\n" +
            "Igatahes, 23. september <b>VÕIKS</b> Tartus olla ning 24. september <b>PEAKS</b> kätte saama … eks me näe 🤷‍️"
      },
      {
        id: 4,
        timeline_title: "04. august 2021",
        title: "Uudised tehasest vol. X",
        content: "Põhimõtteliselt on nii, et juppe pole. Tehases on või oli puhkus. Ja keegi enam ei oska ütelda" +
            " millal auto valmis saab 😭. Elektroonika juppidega on üks hull ikaldus ning tehases on kilomeetrite pikkused" +
            " järjekorrad .. kõik on õudne 😱"
      },
      {
        id: 3,
        timeline_title: "25. mai 2021",
        title: "Uudised tehasest",
        content: "VW esindusest tuli kiri, et auto läheb ilmselt tootmisesse nädalal 25.\n" +
            "<br />Juuli kekpaigaks võiks seega auto Tartus olla 👌"
      },
      {
        id: 2,
        timeline_title: "Aprill 2021",
        title: "Arteon R on tellitud",
        content: "Esialgu on kõik 🙌 ja 🥳, sest sest tellitud sai üks kõige-kõigem Arteon R maailmas 🤭\n" +
            "<br />Mis täpselt kokku sai, sellest kuskil üleval ☝️"
      },
      {
        id: 1,
        timeline_title: "2020",
        title: "Erinevad mõtted ...",
        content: "Esialgu oli üldse plaan GTI uue Golf 8 R vastu vahetada. Plaan ei olnud väga halb 🤭\n" +
            "                <br />\n" +
            "                Küll aga on see uus Golf üsna .. eee .. \"odav\" oma sisult ja olemuselt.\n" +
            "                <br />\n" +
            "                No ja kuna VW kuulutas ilusti välja, et tuleb ka Arteon R, siis väga pikka mõtlemist ei olnudki - tuleb Arteoni\n" +
            "                pigem plaanima hakata 😝\n" +
            "                <br />\n" +
            "                Veebruari lõpus ( 2021 ) läks GTI müüki ja nii see asi veerema hakkaski ..."
      }
    ]
  })
}
</script>
